import { Alert, Badge, Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { logout, selectUser } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../app/i18n";
import styles from "./LoggedIn.module.css";
import NotificationMenu from "../notifications/NotificationMenu";
import logo from "../../brand/Isologotype/samu_white_dark_bckgrnd.png";
import { useEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import ManagerComponent from "../session/ManagerComponent";
import { analytics } from "../../hooks/tracker";
import { Content } from "antd/lib/layout/layout";

const { Header, Footer } = Layout;

const LoggedIn = ({ children }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && analytics) {
      analytics.identify(user._id, {
        firstName: user.name,
        lastName: user.lastName,
        email: user.email,
        avatar: user.image,
        accountId: user.account,
        role: user.role
      });
    }
  }, [user]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const initials = (name) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return <span className={styles.initialsBuble}>{initials}</span>;
  };

  const userIcon = (user) => {
    return initials(user.name);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header">
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[]}
          triggerSubMenuAction="click"
        >
          <Menu.Item
            key="logo"
            style={{ fontFamily: "secular" }}
            className={styles.logoMenuItem}
          >
            <Link to="meetings">
              <img src={logo} alt={__("appName")} style={{ width: "135px" }} />
            </Link>
          </Menu.Item>
          <Menu.Item key="meetings">
            <Link to="meetings">{__("meetings.label")}</Link>
          </Menu.Item>
          <ManagerComponent>
            <Menu.Item key="team">
              <Link to="team">{__("team.label")}</Link>
            </Menu.Item>
          </ManagerComponent>
          <Menu.Item key="analytics">
            <Link to="analytics">{__("analytics.label")}</Link>
          </Menu.Item>
          <Menu.Item key="library">
            <Link to="library">{__("library.label")}</Link>
          </Menu.Item>

          {user.role ? (
            <Menu.Item key="agenda">
              <Link to="agenda">{__("agenda.label")}</Link>
            </Menu.Item>
          ) : (
            <></>
          )}

          <ManagerComponent>
            <Menu.Item key="apps">
              <Link to="apps">{__("apps.label")}</Link>
            </Menu.Item>
          </ManagerComponent>
          <span
            style={{
              position: "absolute",
              right: "175px",
              top: "4px",
              zIndex: 9999,
              cursor: "pointer",
            }}
          >
            <NotificationMenu />
          </span>

          <Menu.Item
            key="account"
            style={{ position: "absolute", right: 0 }}
            onClick={() => setSubmenuOpen((v) => !v)}
          >
            <Menu.SubMenu
              key="SubMenu"
              title={
                <>
                  <>{user && user.name} </>
                  {isSubmenuOpen ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}{" "}
                </>
              }
              icon={userIcon(user)}
            >
              <Menu.Item key="profile">
                <Link to="profile">{__("menu.profile")}</Link>
              </Menu.Item>

              <Menu.Item key="my-next-meetings">
                <Link to="scheduled">{__("myNextMeetings.label")}</Link>
              </Menu.Item>

              {/* <ManagerComponent>
                <Menu.Item key="company-settings">
                  <Link to="settings">{__("menu.settings")}</Link>
                </Menu.Item>
              </ManagerComponent> */}

              <Menu.Item key="two" onClick={handleLogout}>
                {__("menu.logout")}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        {children}
      </Content>
      <Footer
        style={{
          textAlign: "center",
          background: "#1791ff",
          color: "#fff",
          borderTop: "1px solid #ddd",
        }}
      >
        {__("appName")} © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default LoggedIn;
