import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import filtersReducer from '../features/meetings/filters/slice';
import meetingReducer from '../features/meeting/slice';
import sessionReducer from '../features/session/slice';
import teamReducer from '../features/team/slice';
import loadingReducer from '../features/loading/slice';
import agendaReducer from '../features/agenda/slice';
import notificationsReducer from '../features/notifications/slice';
import libraryReducer from '../features/library/slice';
import aiReducer from '../features/meeting/sections/ai/slice';
import callTypeReducer from '../features/meetings/filters/calltypes/slice';
import analyticsReducer from '../features/analytics/slice';
import comparingAnalyticsReducer from '../features/analytics/Compare/slice';
import tagsReducer from '../features/tags/slice';
import marketplaceReducer from '../features/marketplace/slice';
import conversationReducer from '../features/conversation/slice';
import profileReducer from '../features/profile/slice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    filters: filtersReducer,
    meeting: meetingReducer,
    session: sessionReducer,
    team: teamReducer,
    agenda: agendaReducer,
    loading: loadingReducer,
    notifications: notificationsReducer,
    library: libraryReducer,
    ai: aiReducer,
    callType: callTypeReducer,
    analytics: analyticsReducer,
    compareAnalytics: comparingAnalyticsReducer,
    tag: tagsReducer,
    marketplace: marketplaceReducer,
    conversation: conversationReducer,
    profile: profileReducer,
  },
});
